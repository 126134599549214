// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../shared/types/User.bs.js";
import * as DateFns from "../../shared/utils/DateFns.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

function make(actor, createdAt, $$event, id, message, notifiableId, notifiableType, readAt) {
  return {
          actor: actor,
          createdAt: createdAt,
          event: $$event,
          id: id,
          message: message,
          notifiableId: notifiableId,
          notifiableType: notifiableType,
          readAt: readAt
        };
}

function actor(t) {
  return t.actor;
}

function createdAt(t) {
  return t.createdAt;
}

function $$event(t) {
  return t.event;
}

function id(t) {
  return t.id;
}

function message(t) {
  return t.message;
}

function notifiableId(t) {
  return t.notifiableId;
}

function notifiableType(t) {
  return t.notifiableType;
}

function readAt(t) {
  return t.readAt;
}

function decodeEvent($$event) {
  if ($$event === "TopicCreated") {
    return /* TopicCreated */0;
  } else if ($$event === "PostCreated") {
    return /* PostCreated */1;
  } else {
    return /* TargetAdded */2;
  }
}

function makeFromJS(entry) {
  return make(Belt_Option.map(entry.actor, User.makeFromJs), DateFns.decodeISO(entry.createdAt), decodeEvent(entry.event), entry.id, entry.message, entry.notifiableId, entry.notifiableType, Belt_Option.map(entry.readAt, DateFns.decodeISO));
}

function markAsRead(entry) {
  return {
          actor: entry.actor,
          createdAt: entry.createdAt,
          event: entry.event,
          id: entry.id,
          message: entry.message,
          notifiableId: entry.notifiableId,
          notifiableType: entry.notifiableType,
          readAt: Caml_option.some(DateFns.parseISO(Date.now().toString()))
        };
}

export {
  make ,
  actor ,
  createdAt ,
  $$event ,
  id ,
  message ,
  notifiableId ,
  notifiableType ,
  readAt ,
  decodeEvent ,
  makeFromJS ,
  markAsRead ,
}
/* User Not a pure module */
